import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isLoggedIn: false,
    status: 'signup',
    // userName: null,
    token: null
}

const authSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        setSignIn: (state, action="") => {
            state.isLoggedIn = true;
            state.token = action.payload.loginToken;
        },
        setSignOut: (state) => {
            state.isLoggedIn = false;
            state.token = null;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        }
    }
});

export const { setSignIn, setSignOut, setStatus } = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectAuthStatus = (state) => state.auth.status;
// export const selectUserName = (state) => state.userAuth.userName;

export default authSlice.reducer;
