import {
  Header,
  NavContainer,
  MovieInfo,
  Overlay,
  Logo,
} from "./profilenavigation.styles";
import {useDispatch, useSelector} from "react-redux";
import { Outlet, Link } from "react-router-dom";
import NetflixLogo from "../../assets/netflix.png";
import Button from "../../components/button/Button";
import { selectCurrentUser } from "../../store/user/user.selector";
import { signOutUser } from "../../utils/firebase/firebase.utils";
import { selectMovies } from "../../store/movies/movies.selector";
import { motion } from "framer-motion";
import {selectFeatureMovies, selectFeatureShows} from "../../redux/features/movies/moviesSlice";
import {
    MdCalendarToday,
    MdCalendarViewDay,
    MdCalendarViewMonth, MdEditCalendar, MdNewReleases,
    MdOutlineCalendarViewWeek,
    MdPlayCircle, MdTimeline,
    MdTimer
} from "react-icons/md";
import {selectIsLoggedIn, setSignOut} from "../../redux/features/auth/authSlice";

const ProfileNavigation = ({page}) => {
  const currentUser = useSelector(selectIsLoggedIn);
  const popularMovie = useSelector(selectMovies);
  const featuredMovies = useSelector(selectFeatureMovies);
  const featuredShows = useSelector(selectFeatureShows);

  const dispatch = useDispatch()

  // console.log({featuredMovies})


  let heroMovie;
  if (page === "movies"){
      heroMovie = featuredMovies[1]
  }
  else if (page === "tvshows"){
      heroMovie = featuredShows[0]
  }
  else if (page === "children"){
      heroMovie = featuredMovies?.find(el => el?.title === "Storks")
  }
  else{
      heroMovie = featuredMovies[0]
  }

  // console.log({heroMovie})

  return (
    <Header imgUrl={heroMovie?.featuredPosterPath}>
      <Overlay></Overlay>
      <NavContainer>
        <Logo>
          <Link to="/profile">
            <img src={NetflixLogo} alt="logo" />
          </Link>
            <Link className="list-link" to="/browse">
                <p>Home</p>
            </Link>
            <Link className="list-link" to="/tvshows">
                <p>TV Shows</p>
            </Link>
            <Link className="list-link" to="/movies">
                <p>Movies</p>
            </Link>
            <Link className="list-link" to="/children">
                <p>For Children</p>
            </Link>
          <Link className="list-link" to="#">
            <p>My List</p>
          </Link>
        </Logo>
        {currentUser && (
          <div>
            <Link onClick={dispatch(setSignOut)} to="/login">
              <Button buttonText="Sign Out" />
            </Link>
          </div>
        )}
      </NavContainer>
      <MovieInfo
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: "1.2", delayChildren: "0.2" }}
      >
        <motion.h3 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {heroMovie?.title}
        </motion.h3>
        <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {heroMovie?.overview}
        </motion.p>
          <div className="details-container">
              <div className="play-div" >
                  <MdPlayCircle size={"2.5rem"} /> <span className="play-text">Play Now</span>
              </div>
              <div className="runtime-div">
                  <MdTimer size={30}/> <span style={{marginLeft: 10}}>{heroMovie?.runtime}</span>
              </div>
              <div className="release-div">
                  <MdCalendarToday size={30}/> <span style={{marginLeft: 10}}>
                  {new Date(heroMovie?.releaseDate).getFullYear()}
              </span>
              </div>
              <div className="release-div">
                  <span className="play-text">
                  {
                      heroMovie?.genres?.map((genre, index) => <span>{genre?.title?.toUpperCase() + "    "}</span>)
                  }
              </span>
              </div>
          </div>

      </MovieInfo>
      <Outlet />
    </Header>
  );
};

export default ProfileNavigation;
