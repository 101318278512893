import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    details: {},
    similarMovies: [],
    movieVideos: [],
    casts: [],
    isLoading: false,
    error: null,
}

const detailSlice = createSlice({
    name: 'movieDetail',
    initialState,
    reducers: {
        setDetailIsLoading: (state) => {
            state.isLoading = true;
        },
        setDetailsSuccess: (state, action) => {
            state.isLoading = false;
            state.details = action.payload;
        },
        setDetailsClear: (state) => {
            state.details = {};
        },
        setSimilarMovies: (state, action) => {
            state.isLoading = false;
            state.similarMovies = action.payload;
        },
        setMoviesTrailers: (state, action) => {
            state.isLoading = false;
            state.movieVideos = action.payload;
        },
        setDetailsCast: (state, action) => {
            state.isLoading = false;
            state.casts = action.payload;
        },
        setDetailsError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export const { setDetailIsLoading, setDetailsCast,
        setMoviesTrailers, setDetailsClear, setDetailsError, setDetailsSuccess,
        setSimilarMovies, } = detailSlice.actions;

export const selectMovieDetails = (state) => state.moviedetails.details;
export const selectSimilarMovies = (state) => state.moviedetails.similarMovies;
export const selectMovieVideos = (state) => state.moviedetails.movieVideos;
export const selectMovieCasts = (state) => state.moviedetails.casts;
export const selectMovieDetailsIsLoading = (state) => state.moviedetails.isLoading;
export const selectMovieDetailsError = (state) => state.moviedetails.error;

export default detailSlice.reducer;
