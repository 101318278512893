import axios from "axios";

export async function allGenresMovies(page, limit){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/all-genres/${page}/${limit}`)
        // console.log({res})
        // console.log("Data: ", res?.data)
        let docs = res?.data?.data?.docs
        console.log("Docs data: ", docs)
        for (let i=0; i<docs.length;i++){
            let combined = [...docs[i].movies, ...docs[i].tvshows]
            docs[i].combined = combined
        }
        docs = docs?.filter(el => el?.combined?.length > 0)
        console.log({docs})
        return docs
    }
    catch (e) {
        throw e
    }
 }


export async function allGenresTVShows(page, limit){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/all-genres/${page}/${limit}`)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }
}

export async function getGenre(id){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/genre/${id}`)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }
}
