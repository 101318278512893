import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    popularMovies: [],
    trendingNow: [],
    trendingTV: [],
    topRatedMovies: [],
    upcomingMovies: [],
    popularTVShows: [],
    topRatedTVShows: [],
    genres: [],
    isLoading: false,
    error: null,
}

const moviesSlice = createSlice({
    name: 'movies',
    initialState,
    reducers: {
        setMoviesStart: (state) => {
            state.isLoading = true;
        },
        setPopularMovies: (state, action) => {
            state.isLoading = false;
            state.popularMovies = action.payload;
        },
        setTrendingMovies: (state, action) => {
            state.isLoading = false;
            state.trendingNow = action.payload;
        },
        setTrendingTV: (state, action) => {
            state.isLoading = false;
            state.trendingTV = action.payload;
        },
        setTopRatedMovies: (state, action) => {
            state.isLoading = false;
            state.topRatedMovies = action.payload;
        },
        setTopRatedTVShows: (state, action) => {
            state.isLoading = false;
            state.topRatedTVShows = action.payload;
        },
        setUpcomingMovies: (state, action) => {
            state.isLoading = false;
            state.upcomingMovies = action.payload;
        },
        setPopularTVShows: (state, action) => {
            state.isLoading = false;
            state.popularTVShows = action.payload;
        },
        setMoviesFailed: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        setGenres: (state, action) => {
            state.isLoading = false;
            state.genres = action.payload;
        },
        setFeaturedMovies: (state, action) => {
            state.isLoading = false;
            state.featuredMovies = action.payload;
        },
        setFeaturedShows: (state, action) => {
            state.isLoading = false;
            state.featuredShows = action.payload;
        },
    }
});

export const { setMoviesFailed, setMoviesStart, setPopularMovies, setPopularTVShows,
            setTopRatedMovies, setTopRatedTVShows, setTrendingMovies, setTrendingTV,
            setUpcomingMovies, setGenres, setFeaturedMovies, setFeaturedShows} = moviesSlice.actions;

export const selectPopularMovies = (state) => state.movies.popularMovies;
export const selectPopularTVShows = (state) => state.movies.popularTVShows;
export const selectTrendingNow = (state) => state.movies.trendingNow;
export const selectTrendingTV = (state) => state.movies.trendingTV;
export const selectTopRatedMovies = (state) => state.movies.topRatedMovies;
export const selectTopRatedTVShows = (state) => state.movies.topRatedTVShows;
export const selectUpcomingMovies = (state) => state.movies.upcomingMovies;
export const selectMoviesError = (state) => state.movies.error;
export const selectMovieIsLoading = (state) => state.movies.isLoading;
export const selectGenres = (state) => state.movies.genres;
export const selectFeatureMovies = (state) => state.movies.featuredMovies;
export const selectFeatureShows = (state) => state.movies.featuredShows;

export default moviesSlice.reducer;
