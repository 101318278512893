import { ImageCardsContainer, LinkStyle } from "./imagecards.styles";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import {
  fetchMovieDetailsAsync,
  fetchTVDetailAsync,
  fetchSimalarMoviesAsync,
  fetchSimalarTVAsync,
  fetchMovieTrailersAsync,
  fetchTVTrailersAsync,
  fetchMoviesCastsAsync,
  fetchTVCastsAsync,
} from "../../store/moviedetail/detail.action";
import {MdPlayCircle, MdAddCircleOutline, MdInfoOutline, MdTimer} from "react-icons/md";
import {FaChevronDown} from "react-icons/fa";

const ImageCards = ({ movies }) => {
  const dispatch = useDispatch();

  const loadDetailsHandler = () => {
    if ("first_air_date" in movies) {
      dispatch(fetchTVDetailAsync(movies.id));
      dispatch(fetchSimalarTVAsync(movies.id));
      dispatch(fetchTVTrailersAsync(movies.id));
      dispatch(fetchTVCastsAsync(movies.id));
    } else {
      dispatch(fetchMovieDetailsAsync(movies.id));
      dispatch(fetchSimalarMoviesAsync(movies.id));
      dispatch(fetchMovieTrailersAsync(movies.id));
      dispatch(fetchMoviesCastsAsync(movies.id));
    }

    document.body.style.overflow = "hidden";
  };

  return (
    <ImageCardsContainer
      onClick={loadDetailsHandler}
      whileHover={{ scale: 1.2 }}
      layout
    >
      <LinkStyle to={`/player/${movies.title}`}>
        <motion.img
          layout
          // src={`https://image.tmdb.org/t/p/w500${movies.poster_path}`}
          src={movies.posterPath}
          loading="lazy"
          alt={movies.title}
        />
      </LinkStyle>
      <div className="row">
        <span className="name-column">{movies.title?.toUpperCase()}</span>
        <span className="movie-date">{new Date(movies.releaseDate).getFullYear()}</span>
      </div>
      <div className="row">
        <span className="column">
          <p className="language">{movies.originalLanguage.toUpperCase()}</p>
        </span>
        <span className="timer">
          {movies?.runtime && <MdTimer color="white" size={20} style={{marginRight: 20}} />}{movies.runtime || "Series"}
        </span>
      </div>
      {/*<div className="flex"*/}
      {/*     style={{justifyContent: "space-evenly", width: '100%', height: 50,*/}
      {/*       paddingLeft: 10, paddingRight: 10}}>*/}
      {/*  /!*<MdPlayCircle size={30} color={"white"} />*!/*/}
      {/*  <MdAddCircleOutline size={30} color={"white"} />*/}
      {/*  <MdInfoOutline size={30} color={"white"} style={{float: "right"}} />*/}
      {/*</div>*/}
    </ImageCardsContainer>
  );
};

export default ImageCards;
