import ProfileNavigation from "../profilenavigation/ProfileNavigation";
import Cards from "../../components/moviecards/Cards";
import {
  selectMovies,
  selectTrending,
  selectTopRated,
  selectUpcoming,
  selectTVShows,
  selectTopRatedTVShows,
  selectTrendingTV,
} from "../../store/movies/movies.selector";
import {useDispatch, useSelector} from "react-redux";
import { Outlet } from "react-router-dom";
import {
  selectFeatureMovies,
  selectGenres,
  setFeaturedMovies,
  setFeaturedShows,
  setGenres
} from "../../redux/features/movies/moviesSlice";
import FeaturedMovie from "../../components/featured/featuredMovie";
import {useEffect} from "react";
import {allGenresMovies} from "../../services/genreService";
import {getFeaturedMovies, getFeaturedTVShows} from "../../services/movieService";

const UserProfileMovies = () => {
  const popularMovies = useSelector(selectMovies);
  const trendingNow = useSelector(selectTrending);
  const topRatedMovies = useSelector(selectTopRated);
  const upcomingMovies = useSelector(selectUpcoming);
  const popularTVShows = useSelector(selectTVShows);
  const topRatedTVShows = useSelector(selectTopRatedTVShows);
  const trendingTVShows = useSelector(selectTrendingTV);
  const genres = useSelector(selectGenres);

  const dispatch = useDispatch()

  useEffect(() => {
    updateState()
  },[])

  const updateState = async () => {
    let genres = await allGenresMovies(1,1000)
    // console.log({genres})
    dispatch(setGenres(genres))

    let featuredMovies = await getFeaturedMovies()
    let featuredShows = await getFeaturedTVShows()
    // console.log({featuredMovies})
    dispatch(setFeaturedMovies(featuredMovies?.data))
    dispatch(setFeaturedShows(featuredShows?.data))
  }

  // console.log({genres})

  return (
    <>
      <ProfileNavigation page="movies" />
      <FeaturedMovie />
        {/*<Cards categoryTitle="Trending Movies" categoryData={trendingNow} />*/}
        {/*<Cards categoryTitle="Top Rated Movies" categoryData={topRatedMovies} />*/}
        {
            genres?.map((genre, index) => {
              if (genre?.movies?.length>0)
                return <Cards categoryTitle={genre?.title.toUpperCase()} categoryData={genre?.movies} />
            })
        }
      {/*<Cards categoryTitle="Popular on NetFlix" categoryData={popularMovies} />*/}

      {/*<Cards categoryTitle="Movies Coming Soon" categoryData={upcomingMovies} />*/}
      {/*<Cards categoryTitle="Popular TV Shows" categoryData={popularTVShows} />*/}
      {/*<Cards categoryTitle="Trending TV Shows" categoryData={trendingTVShows} />*/}
      {/*<Cards*/}
      {/*  categoryTitle="Top Rated TV Shows"*/}
      {/*  categoryData={topRatedTVShows}*/}
      {/*/>*/}
      <Outlet />
    </>
  );
};

export default UserProfileMovies;
