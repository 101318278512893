import NetflixLogo from "../../assets/netflix.png";
import { Outlet, Link } from "react-router-dom";
import Button from "../button/Button";
import {
  NavigationContainer,
  LogoContainer,
  Header,
} from "./navigation.styles";
import Home from "../../pages/home/Home";
import {useDispatch, useSelector} from "react-redux";
import {selectAuthStatus, setStatus} from "../../redux/features/auth/authSlice";
import {useState} from "react";

const Navigation = () => {
    const dispatch = useDispatch()
    const status = useSelector(selectAuthStatus)

    const [isSignIn, setIsSignIn] = useState(true)

    const toggleSignIn = () => setIsSignIn(!isSignIn)

    console.log({status})

    const buttonClicked = () => {
        toggleSignIn()
        // if (status === "signin"){
        //     dispatch(setStatus('signup'))
        // }
        // else{
        //     dispatch(setStatus('signin'))
        // }

    }

    console.log({status})

  return (
    <Header>
      <NavigationContainer>
        <LogoContainer>
          <Link to="/">
            <img src={NetflixLogo} alt="logo" />
          </Link>
        </LogoContainer>
        <div className="navigation-links">
          <Link to={isSignIn? "login": "signup"}>
            <Button buttonText={isSignIn? "Sign In": "Sign Up"}
            onclick={buttonClicked}/>
          </Link>
        </div>
      </NavigationContainer>
      <Home />

      <Outlet />
    </Header>
  );
};

export default Navigation;
