import axios from "axios";

export async function signUpUser(payload){
    try{
        let res =  await axios.post('https://faithx.mudiaga.com.ng/api/register', payload)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }

    // return axios.post('https://faithx.mudiaga.com.ng/api/register', payload).then((res) => {
    //     console.log("User: ", res.data)
    //     return res.data;
    // }).catch((err) => {
    //     return err
    // })
}

export function signInUser(payload, additionalData){
    console.log({additionalData})
    return axios.post('https://faithx.mudiaga.com.ng/api/register', payload).then((res) => {
        console.log("Sign In success")
        return res.data;
    }).catch((err) => {
        throw err
    })
}
