import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player'
import { MdArrowBack} from "react-icons/md";
import {useNavigate, useParams} from "react-router-dom";
import {getMovieByTitle} from "../../services/movieService";
import {Spinner} from "react-activity";

const Player = () => {
    let params = useParams();
    const navigate = useNavigate();
    const [movie, setMovie] = useState({})

    useEffect(() => {
        getMovieByTitle(params?.title)
            .then(res => {
                console.log({res})
                setMovie(res?.data)
            })
            .catch(err => {
                console.log({err})
            })

    },[])

    console.log({params})

    return (
        <div>
            {
                movie?
                    <ReactPlayer style={{zIndex: -2}} url={movie.videoPath}
                                 width={"100%"}
                                 height={"100vh"}
                                 playing
                                 controls />:
                    <Spinner size={50} color={"blue"} />
            }
            <MdArrowBack size={30}
                style={{size: 3, position: "absolute", top: 50, left: 30, zIndex: 2}}
                onClick={() => window.location.href="/browse"}/>
        </div>
    );
};

export default Player;
