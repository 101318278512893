import React from 'react';
import {FeaturedMovieContainer} from "./featuredMovie.styles";

const FeaturedMovie = (props) => {
    return (
        <div>
            {/*<FeaturedMovieContainer />*/}
        </div>
    );
};

const styles={
    movieContainer: {

    }
}

export default FeaturedMovie;
