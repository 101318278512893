import axios from "axios";

export async function getMovieByTitle(title){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/movies/name/${title}`)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }
}

export async function getFeaturedMovies(){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/featured-movies`)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }
}

export async function getFeaturedTVShows(){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/shows/featured-shows`)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }
}

export async function getTopRatedMovies(){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/toprated-movies`)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }
}

export async function getTopRatedTVShows(){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/show/toprated-shows`)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }
}

export async function getAllTopRated(){
    try{
        let topMovies = await getTopRatedMovies()
        let topShows = await getTopRatedTVShows()

        let res = [...topMovies?.data, ...topShows?.data]
        return res
    }
    catch (e) {
        throw e
    }
}

export async function getTrendingMovies(){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/trending-movies`)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }
}

export async function getTrendingTVShows(){
    try{
        let res =  await axios.get(`https://faithx.mudiaga.com.ng/api/show/trending-shows`)
        console.log({res})
        console.log("Data: ", res?.data)
        return res?.data
    }
    catch (e) {
        throw e
    }
}

export async function getAllTrending(){
    try{
        let topMovies = await getTrendingMovies()
        let topShows = await getTrendingTVShows()

        let res = [...topMovies?.data, ...topShows?.data]
        return res
    }
    catch (e) {
        throw e
    }
}
