// import { configureStore } from "@reduxjs/toolkit";
// import authSlice from "./features/authSlice";
//
// export const store = configureStore({
//     reducer: {
//         userAuth: authSlice
//     },
// })

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice"
import authReducer from "./features/auth/authSlice"
import detailsReducer from "./features/moviedetail/detailSlice"
import moviesReducer from "./features/movies/moviesSlice"
import mylistReducer from "./features/mylist/mylistSlice"
// import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist/es/constants";
// import AsyncStorage from '@react-native-async-storage/async-storage';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    // version: 1,
    storage: storage,
}

const rootReducer = combineReducers({
    user: userReducer,
    auth: authReducer,
    moviedetails: detailsReducer,
    movies: moviesReducer,
    mylist: mylistReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
          serializableCheck: {
              ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
          }
      })
})
