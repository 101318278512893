import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const ImageCardsContainer = styled(motion.div)`
  overflow: hidden;
  margin-top: 2rem;

  img {
    width: 100%;
    height: 300px;
    object-fit: stretch;
    border-radius: 1rem;
  }

  h4 {
    font-size: 1.2rem;
  }
  
  .movie-date{
    float: right;
    color: #d21d1c;
    width: 30%;
    text-align: right;
    padding-right: 10px;
  }

  .timer{
    float: right;
    color: #d21d1c;
    width: 50%;
    text-align: right;
    padding-right: 10px;
  }

  .column {
    float: left;
    width: 50%;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .name-column {
    float: left;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .language{
    color: white;
    border: 2px solid #d21d1c;
    width: 3.5rem;
    padding: 0 5px;
  }
`;

export const LinkStyle = styled(Link)`
  color: var(--white-color);
`;
