import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormInput from "../../components/forminput/FormInput";
import Button from "../../components/button/Button";
import {
  fetchMoviesAsync,
  fetchTrendingAsync,
  fetchTopRatedAsync,
  fetchUpcomingAsync,
  fetchPopularTVShowsAsync,
  fetchTopRatedTVShowsAsync,
  fetchTrendingShowsTVAsync,
} from "../../store/movies/movies.action";
import { signInAuthUserWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { SignInContainer, ParentContainer } from "./signin.styles";
import {allGenresMovies} from "../../services/genreService";
import {setFeaturedMovies, setGenres, setFeaturedShows} from "../../redux/features/movies/moviesSlice"
import {updateProfileData, updateUsername} from "../../redux/features/user/userSlice";
import {getFeaturedMovies, getFeaturedTVShows} from "../../services/movieService";
import {setSignIn} from "../../redux/features/auth/authSlice";

const defaultFormFields = {
  email: "",
  password: "",
};

const SignIn = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [isUserLoading, setIsUserLoading] = useState(false);

  const { email, password } = formFields;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMoviesAsync);
    dispatch(fetchTrendingAsync);
    dispatch(fetchTrendingShowsTVAsync);
    dispatch(fetchTopRatedAsync);
    dispatch(fetchUpcomingAsync);
    dispatch(fetchPopularTVShowsAsync);
    dispatch(fetchTopRatedTVShowsAsync);

    // dispatch()
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUserLoading(true)

    let toPost = {
      email,
      password
    }

    console.log({toPost})

    try {
      const response = await fetch("https://faithx.mudiaga.com.ng/api/login", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toPost),
      });

      const result = await response.json();
      dispatch(updateUsername(result.email))
      dispatch(updateProfileData({payload: result}))
      console.log("Result:", result);
      // localStorage.setItem("authUser", JSON.stringify(result))

      let genres = await allGenresMovies(1,1000)
      console.log({genres})
      dispatch(setGenres(genres))

      let featuredMovies = await getFeaturedMovies()
      let featuredShows = await getFeaturedTVShows()
      console.log({featuredMovies})
      dispatch(setFeaturedMovies(featuredMovies?.data))
      dispatch(setFeaturedShows(featuredShows?.data))
      dispatch(setSignIn(result))
      setIsUserLoading(false)

      if (result.loginToken){
        window.location.href = "/browse"
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  return (
    <ParentContainer>
      <SignInContainer>
        <h2>Sign In</h2>
        <form onSubmit={handleSubmit}>
          <FormInput
            label="Email"
            type="email"
            name="email"
            value={email}
            required
            onChange={handleChange}
          />

          <FormInput
            label="Password"
            type="password"
            name="password"
            value={password}
            required
            onChange={handleChange}
          />

          <Button
            buttonText={isUserLoading ? "Loading..." : "Sign In"}
            type="submit"
            isUserLoading={isUserLoading}
          />
        </form>
      </SignInContainer>
    </ParentContainer>
  );
};

export default SignIn;
