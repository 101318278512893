import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    myList: [],
    isLoading: false,
    error: null,
}

const mylistSlice = createSlice({
    name: 'movies',
    initialState,
    reducers: {
        setListStart: (state) => {
            state.isLoading = true;
        },
        setListItems: (state, action) => {
            state.isLoading = false;
            state.myList = action.payload;
        },
        setListFailed: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export const { setListFailed, setListItems, setListStart } = mylistSlice.actions;

export const selectMyList = (state) => state.mylist.myList;
export const selectMyListError = (state) => state.mylist.error;
export const selectMyListIsLoading = (state) => state.mylist.isLoading;

export default mylistSlice.reducer;
